<template>
    <div class="news">
        <div class="content">
            <div class="line_01">{{$route.query.title}}</div>
            <el-input placeholder="请输入内容查询" v-model="title" clearable style="margin:50px 0px;" v-if="id==10">
                <div slot="append" @click="getList">搜索</div>
            </el-input>
            <div class="flex cursor" v-for="(item,i) in list" :key="i" @click="$router.push({path:'/newsDetails',query:{id:item.id}})">
                <img :src="item.thumb" alt="" v-if="item.thumb">
                <div class="fx">
                    <div class="left">
                        <div>{{item.title}}</div>
                        <div class="desc">{{item.desc}}</div>
                        <div>{{item.author}} 阅读：{{item.views}}</div>
                    </div>
                    <div class="right">
                        <div style="font-size:24px;">{{item.created_at.slice(5,7)}} / {{item.created_at.slice(8,10)}} </div>
                        <div style="text-align:right;">{{item.created_at.slice(0,4)}} </div>
                    </div>
                </div>
            </div>
        </div>
        <Page v-if="list.length!=0" :pageSize="page_size" :total="total" :current="page" @change="pageChange"/>
        <div class="t-center" style="padding:40px 0px;" v-else>
            <img src="shexie/img/nodata1.png" alt="">
            <div>暂无数据</div>
        </div>
    </div>
</template>

<script>
import {articles} from '@/api/news.js'
export default {
    data() {
        return {
            page_size:10,   //每页条数
            total:0,        //总条数
            page:1,        //当前页
            list:[],
            title:null
        };
    },
    props:{
        id:{
            default:null,
            type:Number | String
        },
    },
    watch:{
        id:{  
            handler:function(val){ 
                this.getList()
            },  
            immediate:true,//关键
            deep:true
        },
    },
    created() {
        // this.getList()
    },
    mounted() {

    },
    methods: {
        getList(){
            articles({cate_id:this.id,title:this.title,page:this.page,page_size:this.page_size}).then(res=>{
                if(res.code==200){
                    this.list = res.data.data
                    this.total = res.data.total
                }else{
                    this.list = []
                    this.total = 0
                }
            })
        },
        //分页
        pageChange(val){
            this.page  = val;
            this.getList()
        },
    }
};
</script>

<style scoped lang="scss">
    .news{
        margin:20px auto;
        .content{
            margin-top:40px;
            .line_01{ 
                width:100px;
                padding: 0 20px 0;  
                margin:0px auto 30px;  
                line-height: 2px;  
                color: #CD0000;
                font-weight: bold;
                border-left: 80px solid #CD0000;  
                border-right: 80px solid #CD0000;  
                text-align: center;  

            }
            /deep/ .el-input__inner{
                height: 47px;
                line-height: 48px;
            }
            /deep/ .el-input-group__append{
                background: #CC0000;
                color:#fff;
                width: 78px;
                text-align: center;
                line-height:46px;
                cursor: pointer;
            }
            .flex{
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid #000;
                align-items: center;
                img{
                    width: 218px;
                    height: 160px;
                    margin-right: 20px;
                }
                .fx{
                    width: 100%;
                    .left{
                        padding-right: 40px;
                        .desc{
                            height: 100px;
                            color: #888;
                            font-size: 14px;
                            margin-top:10px;
                        }
                    }
                    .right{
                        flex: 0 0 80px;
                        border-left: 2px solid #9A9794;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
</style>
